import { Injectable } from '@angular/core';
import { IGATrackingData } from '../interfaces/iGATrackingData';

@Injectable({
  providedIn: 'root'
})
export class GALoggingService {
  constructor() {}

  public logGAEvent(gaTrackingData: IGATrackingData) {
    // @ts-ignore
    dataLayer.push({
      event: gaTrackingData.event,
      'wa.label': gaTrackingData.label,
      'wa.section': gaTrackingData.section
    });
  }
}
