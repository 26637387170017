import { Injectable } from '@angular/core';
import { ActivityLoggerService } from './activity-logger.service';
import { TrackingIdsService } from './tracking-ids.service';
import { SessionStorageService } from './session-storage.service';
import { ITrackingData } from '../interfaces/iTrackingData';

@Injectable({
  providedIn: 'root'
})
export class SetLoggerDataService {
  private activityLog;
  constructor(
    private activityLogService: ActivityLoggerService,
    private trackingIdsService: TrackingIdsService,
    private sessionStorageService: SessionStorageService
  ) {}

  public setLogToActivityLogService(
    type: string,
    id: string,
    title: string,
    trackingServiceName: string
  ) {
    const SOURCE_URL = window.location.href;
    const tracking = this.trackingIdsService.getTrackingIdForPath(
      trackingServiceName
    );
    const userName = this.getUserName();
    const userAgent = window.navigator.userAgent;

    this.activityLog = {
      user: {
        username: userName,
        trackingId: tracking
      },

      object: {
        type: type,
        id: id,
        source: SOURCE_URL,
        title: title,
        device: {
          userAgent: userAgent
        }
      },

      action: {
        type: 'click'
      }
    };
    this.activityLogService.loggingWithSync(this.activityLog).subscribe();
  }

  private getUserName(): string {
    const token = this.sessionStorageService.getKey('id_token_claims_obj');
    if (token) {
      return token.deakin_username;
    }
    return '';
  }
}
