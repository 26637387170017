import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AppConfigService } from './app-config/app-config.service';
import { IActivityLogger } from '../interfaces/iActivityLogger';

@Injectable({
  providedIn: 'root'
})
export class ActivityLoggerService {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {}

  public loggingWithSync(activitylogging: IActivityLogger): Observable<any> {
    const currentTime = new Date();
    const requestBody = {
      time: currentTime,
      user: activitylogging.user,
      action: activitylogging.action,
      object: activitylogging.object
    };
    return this.http.post(this.appConfigService.loggerUrl, requestBody, {
      observe: 'response'
    });
  }
}
