import {
  Directive,
  Input,
  HostListener
} from '@angular/core';
import { GALoggingService } from 'src/app/services/ga-logging.service';
import { IGATrackingData } from 'src/app/interfaces/iGATrackingData';
import { SetLoggerDataService } from '../../services/set-logger-data.service';
import { IUserLevelTrackingData } from '../../interfaces/IUserLevelTrackingData';

@Directive({
  selector: '[appTrackingEvents]'
})
export class TrackingEventsDirective {
  @Input() gaTrackingData: IGATrackingData;
  @Input() activityLogData: IUserLevelTrackingData;

  constructor(
    private loggingService: GALoggingService,
    private setLog: SetLoggerDataService
  ) {}

  @HostListener('click')
  onClick() {
    this.logGoogleAnalyticsTracking(this.gaTrackingData);
    this.logUserLevelTracking(this.activityLogData);
    // TODO: get attribute on element manually
  }

  logGoogleAnalyticsTracking(gaTrackingData: IGATrackingData) {
    if (
      gaTrackingData !== undefined &&
      this.getObjectSize(gaTrackingData) >= 1
    ) {
      this.loggingService.logGAEvent(gaTrackingData);
    }
  }

  logUserLevelTracking(activityLogData: IUserLevelTrackingData) {
    if (
      activityLogData !== undefined &&
      this.getObjectSize(activityLogData) >= 1
    ) {
      this.setLog.setLogToActivityLogService(
        activityLogData.type,
        activityLogData.id,
        activityLogData.title,
        activityLogData.trackingServiceName
      );
    }
  }

  private getObjectSize(obj): number {
    return Object.keys(obj).length;
  }
}
