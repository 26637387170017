import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IntroExpPlaceholderComponent } from './ui-components/placeholder/header/introexp-placeholder.component';
import { PlaceholderComponent } from './ui-components/placeholder/placeholder.component';
import { WelcomePlaceholderComponent } from './ui-components/placeholder/welcome/welcome-placeholder.component';
import { MyLearningPlaceholderComponent } from './ui-components/placeholder/my-learning/my-learning-placeholder.component';
import { FeaturedPlaceholderComponent } from './ui-components/placeholder/featured/featured-placeholder.component';
import { NewsPlaceholderComponent } from './ui-components/placeholder/news/news-placeholder.component';
import { HomePlaceholderComponent } from './ui-components/placeholder/home/home-placeholder.component';

import { PlaceholderDirective } from './directives/placeholder.directive';
import { DeferLoadDirective } from './directives/deferload.directive';
import { DeferloaderComponent } from './ui-components/deferloader/deferloader.component';
import { PageSliderModule } from './ui-components/page-slider/page-slider.module';
import { SkeletonComponent } from '@esolutions/design-system-ng/components'

@NgModule({
  declarations: [
    PlaceholderDirective,
    DeferLoadDirective,
    PlaceholderComponent,
    DeferloaderComponent,
    IntroExpPlaceholderComponent,
    WelcomePlaceholderComponent,
    MyLearningPlaceholderComponent,
    FeaturedPlaceholderComponent,
    NewsPlaceholderComponent,
    HomePlaceholderComponent
  ],
    imports: [
        CommonModule,
        PageSliderModule,
        SkeletonComponent
    ],
  exports: [
    PlaceholderDirective,
    DeferLoadDirective, // TODO: move to directive.modules.ts
    PlaceholderComponent,
    DeferloaderComponent,
    IntroExpPlaceholderComponent,
    WelcomePlaceholderComponent,
    MyLearningPlaceholderComponent,
    FeaturedPlaceholderComponent,
    NewsPlaceholderComponent,
    HomePlaceholderComponent
  ]
})
export class SharedModule {}
