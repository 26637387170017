<div class="wrapper">
  <h2 class="h3 modal-heading make-space-for-close-button">
    Privacy statement for DeakinSync Users
  </h2>
  <div class="content">
    <p>Effective date: 24 January 2022</p>
    <hr />
    <p>
      This Privacy Statement explains how Deakin University (Deakin) will
      collect, use, disclose and manage the information that identifies you
      (<strong>personal information</strong>) when you use DeakinSync.
    </p>
    <h3 class="h6">
      What personal information does Deakin collect when I use DeakinSync?
    </h3>
    <p>
      DeakinSync accesses existing information sources from within the
      University (such as your enrolment and learning unit sites) but does not
      store information from those sources within the portal.
    </p>
    <p>
      DeakinSync will create log files that record when each student accesses
      the portal. Log information is used to understand system usage, for
      troubleshooting problems and improving Sync services.
    </p>
    <p>
      DeakinSync also records which portal components are accessed by each
      student. We will use this information to further personalize your
      experience by using the context of your usage to improve the relevancy of
      information Sync provides throughout the student journey. In other cases,
      your usage data will be deidentified where practicable.
    </p>
    <h3 class="h6">Why does Deakin collect this personal information?</h3>
    <p>
      Deakin collects your personal information to:
    </p>
    <ol type="a">
      <li>Troubleshooting system issues</li>
      <li>
        Understand how students use the portal to make future improvements
      </li>
      <li>Personalise your experience.</li>
    </ol>
    <h3 class="h6">How does Deakin use or disclose my personal information?</h3>
    <p>
      Deakin will use information collected while you use DeakinSync to
      understand the way students use the portal and what features they may want
      in the future, to understand the student learning experience, analysis and
      reporting, to provide additional learning support, and to improve the
      portal to be more responsive to student needs. DeakinSync will also
      personalise the experience by identifying other resources relevant to the
      student.
    </p>
    <p>
      It will also be used for those purposes set out in the
      <a
        href="https://www.deakin.edu.au/students/enrolment-fees-and-money/enrolments/student-information-privacy"
        target="_blank"
        >Information Privacy for Students</a
      >
      statement.
    </p>
    <p>
      As with all personal information Deakin may use or disclose that
      information where authorised or required by law.
    </p>
    <h3 class="h6">What information is retained and where is it stored.</h3>
    <p>
      The Sync platform stores your usage information of DeakinSync in our
      onsite infrastructure.
    </p>
    <h3 class="h6">Is my personal information secure?</h3>
    <p>
      Deakin provides a secure environment for personal information collected by
      DeakinSync. DeakinSync is hosted internally at Deakin and your personal
      information is not stored offshore or in a cloud-based environment. All
      usage records are stored securely within Deakin’s password-protected IT
      system.
    </p>
    <p>
      You also have a role to play in keeping your personal information secure
      when you use DeakinSync. You should ensure that your desktop or mobile
      device is not left unattended with DeakinSync active. You should also use
      a strong password on your device and adjust your settings so that your
      device locks after a short period of inactivity.
    </p>
    <p>
      Deakin University manages personal information it holds, including
      requests by individuals for access to their personal information, in
      accordance with the Privacy and Data Protection Act 2014 (Vic) and the
      Health Records Act 2001 (Vic) and, where relevant, the Privacy Act 1988
      (Cth). Deakin’s privacy policy may be viewed in Deakin’s Policy Library at
      <a
        href="https://policy.deakin.edu.au/view.current.php?id=00139"
        target="_blank"
        >https://policy.deakin.edu.au/view.current.php?id=00139</a
      >
      and further information about privacy at Deakin is available at
      <a href="http://www.deakin.edu.au/footer/privacy" target="_blank"
        >http://www.deakin.edu.au/footer/privacy</a
      >.
    </p>
  </div>
  <button class="close-button" aria-label="Close" (click)="close()">
    <span class="far fa-times" aria-hidden="true"></span>
  </button>
</div>
