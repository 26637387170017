import { Component, Input, OnInit } from '@angular/core';
import { TermsOfUseComponent } from '../../modal-content/terms-of-use/terms-of-use.component';
import { PrivacyComponent } from '../../modal-content/privacy/privacy.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  popup = false;

  @Input()
  public delay = 0;

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {
    if (this.delay > 0) {
      setTimeout(() => {
        this.delay = 0;
      }, this.delay);
    }
  }

  openTermsModal() {
    this.matDialog.open(TermsOfUseComponent, {
      autoFocus: false,
      width: '528px',
      maxHeight: '100vh',
      panelClass: 'custom-dialog-container'
    });
  }

  openPrivacyModal() {
    this.matDialog.open(PrivacyComponent, {
      autoFocus: false,
      width: '528px',
      maxHeight: '100vh',
      panelClass: 'custom-dialog-container'
    });
  }
}
