<footer *ngIf="delay === 0" role="contentinfo" aria-label="site Footer">
  <div class="sync-page-container">
    <nav id="footer-nav" aria-label="Footer">
      <ul class="footer-link-list">
        <li class="list-item">
          <a
            href="https://www.deakin.edu.au/copyright"
            target="_blank"
            appTrackingEvents
            [activityLogData]="{
              type: 'footer-item',
              id: 'Copyright link',
              title: 'Copyright',
              trackingServiceName: 'footer'
            }"
            [gaTrackingData]="{
              event: 'wa.click',
              label: 'copyright',
              section: 'footer-item'
            }"
            >Copyright</a
          >
        </li>
        <li class="list-item">
          <a
            href="https://www.deakin.edu.au/web-disclaimer"
            target="_blank"
            appTrackingEvents
            [activityLogData]="{
              type: 'footer-item',
              id: 'Disclaimer link',
              title: 'Disclaimer',
              trackingServiceName: 'footer'
            }"
            [gaTrackingData]="{
              event: 'wa.click',
              label: 'disclaimer',
              section: 'footer-item'
            }"
            >Disclaimer</a
          >
        </li>
        <li class="list-item">
          <button
            tabindex="0"
            (click)="openPrivacyModal()"
            id="privacy"
            class="popup"
            appTrackingEvents
            [activityLogData]="{
              type: 'footer-item',
              id: 'Privacy link',
              title: 'Privacy',
              trackingServiceName: 'footer'
            }"
            [gaTrackingData]="{
              event: 'wa.click',
              label: 'privacy',
              section: 'footer-item'
            }"
          >
            Privacy
          </button>
        </li>
        <li class="list-item">
          <button
            tabindex="0"
            (click)="openTermsModal()"
            id="terms-of-use"
            class="popup"
            appTrackingEvents
            [activityLogData]="{
              type: 'footer-item',
              id: 'Terms of Use link',
              title: 'Terms of Use',
              trackingServiceName: 'footer'
            }"
            [gaTrackingData]="{
              event: 'wa.click',
              label: 'terms-of-use',
              section: 'footer-item'
            }"
          >
            Terms of Use
          </button>
        </li>
      </ul>
    </nav>
    <p class="footer-para">Deakin University CRICOS Provider Code: 00113B</p>
    <p class="footer-para">
      We acknowledge the traditional owners of the lands on which Deakin
      University stands and we pay our respect.
    </p>
  </div>
</footer>
